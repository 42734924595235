import React from 'react'
import { inheritClassNames, inheritProps  } from '../lib/helpers'

export const CloseButton = props => {
  return (
    <div className={`close-button ${inheritClassNames(props)}`} {...inheritProps(props, ["onClick"])} aria-label="Close button">
      <svg width="19" height="20" viewBox="0 0 19 20" fill="none">
        <path
          d="M17.7353 1.76464L9.49983 10.0001M9.49983 10.0001L0.764694 18.7352M9.49983 10.0001L0.764648 1.26489M9.49983 10.0001L17.7352 18.2355"
          stroke={props.stroke ?? 'black'}
          vectorEffect="non-scaling-stroke"
        />
      </svg>
    </div>
  )
}

export default CloseButton

