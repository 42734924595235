import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { imageUrlFor } from '../lib/image-url'
import { buildImageObj, isWarpC } from '../lib/helpers'

// https://ogp.me

function SEO({ description, lang, meta, noindex, keywords, title, image, bodyAttr }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription = description || (data.site && data.site.openGraph.description) || ''
        const siteTitle = (data.site && data.site.openGraph.title) || ''
        const siteAuthor = isWarpC() ? 'Warp Composers' : 'Warp Publishing'
        const keywords = (data.site && data.site.keywords) || []
        const metaImage =
          image && image.asset
            ? imageUrlFor(buildImageObj(image))
                .width(1200)
                .url()
            : ''

        const pageTitle = title || siteTitle
        const robots = noindex
          ? 'noindex, nofollow'
          : process.env.GATSBY_NOINDEX === 'true'
          ? 'noindex, nofollow'
          : 'index, follow'
        const googleSiteVerification = process.env.GATSBY_GOOGLE_SITE_VERIFICATION
          ? process.env.GATSBY_GOOGLE_SITE_VERIFICATION
          : ''

        return (
          <Helmet
            bodyAttributes={bodyAttr}
            htmlAttributes={{ lang }}
            title={pageTitle}
            titleTemplate={pageTitle === siteTitle ? siteTitle : `%s | ${siteTitle}`}
            meta={[
              {
                name: 'google-site-verification',
                content: googleSiteVerification,
              },
              {
                name: 'description',
                content: metaDescription,
              },
              {
                property: 'og:title',
                content: pageTitle,
              },
              {
                property: 'og:description',
                content: metaDescription,
              },
              {
                property: 'og:type',
                content: 'website',
              },
              {
                property: 'og:image',
                content: metaImage,
              },
              {
                name: 'twitter:card',
                content: 'summary_large_image',
              },
              {
                name: 'twitter:creator',
                content: siteAuthor,
              },
              {
                name: 'twitter:title',
                content: pageTitle,
              },
              {
                name: 'twitter:description',
                content: metaDescription,
              },
              {
                name: 'robots',
                content: robots,
              },
            ]
              .concat(
                keywords && keywords.length > 0
                  ? {
                      name: 'keywords',
                      content: keywords.join(', '),
                    }
                  : []
              )
              .concat(meta)}
          ></Helmet>
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      keywords
      openGraph {
        title
        description
      }
    }
  }
`
