import React from 'react'
import { graphql } from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import LicensingForm from '../components/licensing-form'
import { mapEdgesToNodes } from '../lib/helpers'

export const query = graphql`
  query LicensingFormTemplateQuery($id: String!) {
    licensingForm: sanityLicensingForm(id: { eq: $id }) {
      id
      title
      slug {
        current
      }
      faqRoute {
        slug {
          current
        }
      }
      columns {
        leftColumn {
          title
        }
        rightColumn: _rawRightColumn
      }
    }
    artists: allSanityArtist(
      sort: { fields: [title], order: ASC }
      filter: { slug: { current: { ne: null } }, isVisible: { eq: true }, isIndexed: { eq: true } }
    ) {
      edges {
        node {
          title
        }
      }
    }
  }
`

const LicensingFormTemplate = props => {
  const { data, errors } = props
  const licensingForm = data && data.licensingForm
  const artists = data && mapEdgesToNodes(data?.artists).map(artist => artist.title)

  return (
    <>
      {errors && <SEO title="GraphQL Error" />}
      {licensingForm && <SEO title={licensingForm.title || 'Untitled'} />}
      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      {licensingForm && <LicensingForm {...licensingForm} {...{ artists }} />}
    </>
  )
}

export default LicensingFormTemplate
